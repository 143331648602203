import React from "react";
import { Box, Typography } from "@mui/material";
import MembersImage from "../../../assets/jonh_doe.webp";
import AOS from "aos";
import "aos/dist/aos.css";

const Recognization = () => {
  return (
    <div>
      <Box
        sx={{
          width: "85%",
          display: "flex",
          flexDirection: "column",
          margin: "auto",
        }}
      >
        <Typography
          variant="h1"
          color="#fff"
          fontWeight="bold"
          sx={{ fontSize: "56px", textAlign: "center", marginBottom: "50px" }}
        >
          Recognization
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "100px",
            margin: "50px 0px",
          }}
        >
          <Box
            sx={{
              height: "30vh",
              display: "flex",
              overflow: "hidden",
            }}
            data-aos="zoom-out"
          >
            <img
              src="https://static.vecteezy.com/system/resources/previews/002/349/754/non_2x/modern-elegant-certificate-template-free-vector.jpg"
              alt="image"
              width="100%"
              style={{ objectFit: "cover" }}
              
            />
          </Box>
          <Box
            sx={{
              height: "30vh",
              display: "flex",
              overflow: "hidden",
            }}
            data-aos="zoom-out"
          >
            <img
              src="https://images.bannerbear.com/requests/images/008/924/579/original/eac42cabf39c21cf858e5f844efc4a0522f8e179.png?1632808302"
              alt="image"
              width="100%"
              style={{ objectFit: "cover" }}
              
            />
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default Recognization;
