import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Box, Grid, Typography } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";

const blogs = [
  {
    id: 1,
    title:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard ",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard ",
    image:
      "https://images.unsplash.com/photo-1724582586413-6b69e1c94a17?q=80&w=1856&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: 2,
    title:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard ",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard ",
    image:
      "https://plus.unsplash.com/premium_photo-1692640261266-e587473ddee9?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: 3,
    title:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard ",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard ",
    image:
      "https://images.unsplash.com/photo-1724592752478-c4b75032d7e0?q=80&w=2039&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: 4,
    title:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard ",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard ",
    image:
      "https://images.unsplash.com/photo-1720048171731-15b3d9d5473f?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: 5,
    title:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard ",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard ",
    image:
      "https://images.unsplash.com/photo-1720048171731-15b3d9d5473f?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
];

const BlogCarousel = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1250 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1250, min: 900 },
      items: 3,
    },
    miniTablet: {
      breakpoint: { max: 900, min: 600 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
    },
  };

  return (
    <div>
      <Carousel
        responsive={responsive}
        ssr={true}
        infinite={false}
        arrows={true}
        removeArrowOnDeviceType={["miniTablet", "mobile"]}
        autoPlay={false}
        autoPlaySpeed={3000}
        keyBoardControl={true}
        customTransition="transform 300ms ease-in-out"
        transitionDuration={500}
        containerClass="carousel-container"
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
        {blogs.slice(0, 5).map((blog) => {
          return (
            <Box
              key={blog.id}
              sx={{
                width: "85%",
                margin: "0px 10px",
                border: "2px solid white",
                padding: "20px",
                borderRadius: "24px",
              }}
            >
              <Box sx={{ height: "40vh", width: "100%" }}>
                <img
                  alt="thumbnail"
                  width="100%"
                  height="100%"
                  src={blog.image}
                  style={{
                    objectFit: "cover",
                  }}
                />
              </Box>

              <Box
                sx={{
                  marginTop: "10px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <Typography variant="h5" sx={{ color: "white" }}>
                    {blog.title}
                  </Typography>
                  <Typography variant="h4">
                    <FavoriteIcon
                      sx={{ display: "flex", fontSize: "36px", color: "red" }}
                    />
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", gap: "10px", justifyContent:'center' }}>
                  <Typography
                    variant="h6"
                    sx={{
                      padding: "0px 15px",
                      borderRadius: "12px",
                      color: "white",
                      border: "2px solid white",
                    }}
                  >
                    Aquaponics
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                        padding: "0px 15px",
                      borderRadius: "12px",
                      color: "white",
                      border: "2px solid white",
                    }}
                  >
                    Sustainability
                  </Typography>
                </Box>
              </Box>
            </Box>
          );
        })}
      </Carousel>
    </div>
  );
};

export default BlogCarousel;
