import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import MembersImage from "../../../assets/jonh_doe.webp";

const members = [
  { name: "John Smith", image: MembersImage },
  { name: "Emily Johnson", image: MembersImage },
  { name: "Michael Brown", image: MembersImage },
  { name: "Jessica Williams", image: MembersImage },
];

const JoinedMembers = () => {
  return (
    <Box
      sx={{
        width: "85%",
        display: "flex",
        flexDirection: 'column',
        margin: "auto",
        textAlign: "center",
      }}
    >
      <Typography
        variant="h1"
        color="#fff"
        fontWeight="bold"
        sx={{ fontSize: "56px", marginBottom: "50px" }}
      >
        Why Join Us?
      </Typography>

      <Grid container spacing={4} justifyContent="center">
        {members.map((member, index) => (
          <Grid item key={index} xs={12} sm={6} md={3}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Box sx={{ height: "200px", width: "200px", borderRadius: '100px', overflow: 'hidden' }}>
                <img src={member.image} alt={member.name} width="100%" style={{ objectFit: 'cover' }} />
              </Box>
              <Typography
                variant="h3"
                color="#fff"
                fontWeight="bold"
                sx={{ marginTop: "10px" }}
              >
                {member.name}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default JoinedMembers;