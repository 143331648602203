import React from "react";
import {
  Box,
  Typography,
  Grid,
  TextField,
  Button,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import BlogList from "./blogs/BlogList";
import BlogImage1 from "../../assets/blog-1.png";
import BlogImage2 from "../../assets/blog-2.png";
import SearchIcon from "@mui/icons-material/Search";

const Blog = () => {
  return (
    <div
      style={{
        backgroundColor: "#041e04",
        paddingTop: "200px",
        paddingBottom: "150px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "90%",
          margin: "auto",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: {xs:'99%', sm:'99%', md:'50%'},
            margin:'auto',
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px 35px",
          }}
        >
          <Typography
            variant="h1"
            fontWeight="bold"
            sx={{
              fontSize: "56px",
              textAlign: "center",
              marginBottom: "50px",
              color: "#fff",
            }}
          >
            We don't just innovate we write also
          </Typography>
          <form style={{ width: "100%" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8} md={8}>
                <TextField
                  InputLabelProps={{
                    sx: {
                      color: "white",
                    },
                  }}
                  InputProps={{
                    sx: {
                      color: "white",
                      "& .MuiInputBase-input": {
                        color: "white",
                      },
                      "&::placeholder": {
                        color: "white",
                        opacity: 1,
                      },
                    },
                  }}
                  variant="outlined"
                  fullWidth
                  sx={{
                    backgroundColor: "transparent",
                    borderRadius: "4px",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "white",
                      },
                      "&:hover fieldset": {
                        borderColor: "white",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "white",
                      },
                    },
                  }}
                  placeholder="Email"
                />
              </Grid>
              <Grid
                item
                xs={12} sm={4} md={4}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    width: "100%",
                    color: "black",
                    padding: "10px",
                    borderRadius: "50px",
                    backgroundColor: "#e4ba01",
                    fontWeight: "bold",
                    "&:hover": {
                      color: "white",
                      backgroundColor: "#e4ba01",
                    },
                  }}
                  type="submit"
                >
                  Sign up here
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          width: {xs:'99%', sm:'99%', md:'90%'},
          margin: "auto",
          alignItems: "center",
          marginTop:'100px',
          marginBottom:'100px'
        }}
      >
        <form style={{ width: "50%" }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                InputLabelProps={{
                  sx: {
                    color: "black",
                  },
                }}
                InputProps={{
                  sx: {
                    color: "white",
                    "& .MuiInputBase-input": {
                      color: "black",
                    },
                    "&::placeholder": {
                      color: "black",
                      opacity: 1,
                    },
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                fullWidth
                sx={{
                  backgroundColor: "white",
                  borderRadius: "4px",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "black",
                    },
                    "&:hover fieldset": {
                      borderColor: "black",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "black",
                    },
                  },
                }}
                placeholder="Search"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl
                fullWidth
                sx={{
                  backgroundColor: "white",
                  borderRadius: "4px",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "black",
                    },
                    "&:hover fieldset": {
                      borderColor: "black",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "black",
                    },
                  },
                }}
              >
                <Select
                  displayEmpty
                  id="position"
                  variant="outlined"
                  sx={{
                    color: "black",
                    backgroundColor: "transparent",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "black",
                      },
                      "&:hover fieldset": {
                        borderColor: "black",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "black",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "black",
                    },
                  }}
                  renderValue={(selected) => {
                    if (!selected) {
                      return (
                        <span style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                          Select Position
                        </span>
                      );
                    }
                    return selected;
                  }}
                >
                  <MenuItem value="">
                    <em>Select Position</em>
                  </MenuItem>
                  <MenuItem value="First">First</MenuItem>
                  <MenuItem value="Second">Second</MenuItem>
                  <MenuItem value="Third">Third</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </Box>

      <Box sx={{ marginTop: "50px" }}>
        <BlogList />
      </Box>
    </div>
  );
};

export default Blog;
