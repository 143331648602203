import React from "react";
import { Box, Typography, Grid, Container } from "@mui/material";
import { Link } from "react-router-dom";
import HeroSection from "../heroSection";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const Footer = () => {
  return (
    <footer
      style={{
        backgroundColor: "#083d08",
        color: "#fff",
        height: "60vh",
        display: "flex",
        alignItems: "center",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img src="" alt="logo" />
              <Typography
                variant="h4"
                fontWeight="bold"
                sx={{ marginLeft: "10px" }}
              >
                Creative Path Breaker
              </Typography>
            </Box>
            <Box>
              <Typography variant="h5" sx={{ marginTop: "30px" }}>
                &copy; 2024 Creative Path Breaker
              </Typography>
              <Box sx={{ display: "flex", marginTop: "30px", gap:'10px' }}>
                <Link style={{textDecoration:'none'}}>
                  <FacebookIcon sx={{fontSize:'32px', color:'#0866ff'}}/>
                </Link>
                <Link>
                  <InstagramIcon sx={{fontSize:'32px', color:'#cd486b'}}/>
                </Link>
                <Link>
                  <YouTubeIcon sx={{fontSize:'32px', color:'#FF0000'}}/>
                </Link>
                <Link>
                  <LinkedInIcon sx={{fontSize:'32px', color:'#0077B5 '}}/>
                </Link>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography variant="h3" fontWeight="bold">
              Contact
            </Typography>
            <Box sx={{ marginTop: "20px" }}>
              <Typography variant="h5" color="#ced8ce">
                CDA-Sector-9. Cuttack, 731245
              </Typography>
              <Typography variant="h5" color="#ced8ce">
                Odisha, India
              </Typography>
              <Typography variant="h5" color="#ced8ce">
                <Link
                  to="/"
                  style={{
                    textDecoration: "none",
                    color: "#ced8ce",
                    "&:hover": {
                      textDecoration: "none",
                    },
                  }}
                >
                  +91 87354 93627
                </Link>
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography variant="h3" fontWeight="bold">
              Navigation
            </Typography>
            <Box sx={{ marginTop: "20px" }}>
              <Typography variant="h5" color="#ced8ce">
                <Link
                  to="/"
                  style={{
                    textDecoration: "none",
                    color: "#ced8ce",
                    "&:hover": {
                      textDecoration: "none",
                    },
                  }}
                >
                  Home
                </Link>
              </Typography>
              <Typography variant="h5" color="#ced8ce">
                <Link
                  to="/"
                  style={{
                    textDecoration: "none",
                    color: "#ced8ce",
                    "&:hover": {
                      textDecoration: "none",
                    },
                  }}
                >
                  About
                </Link>
              </Typography>
              <Typography variant="h5" color="#ced8ce">
                <Link
                  to="/"
                  style={{
                    textDecoration: "none",
                    color: "#ced8ce",
                    "&:hover": {
                      textDecoration: "none",
                    },
                  }}
                >
                  Contact
                </Link>
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography variant="h3" fontWeight="bold">
              Legal
            </Typography>
            <Box sx={{ marginTop: "20px" }}>
              <Typography variant="h5" color="#ced8ce">
                <Link
                  to="/"
                  style={{
                    textDecoration: "none",
                    color: "#ced8ce",
                    "&:hover": {
                      textDecoration: "none",
                    },
                  }}
                >
                  Privacy Policy
                </Link>
              </Typography>
              <Typography variant="h5" color="#ced8ce">
                <Link
                  to="/"
                  style={{
                    textDecoration: "none",
                    color: "#ced8ce",
                    "&:hover": {
                      textDecoration: "none",
                    },
                  }}
                >
                  Cookie Agreement
                </Link>
              </Typography>
              <Typography variant="h5" color="#ced8ce">
                <Link
                  to="/"
                  style={{
                    textDecoration: "none",
                    color: "#ced8ce",
                    "&:hover": {
                      textDecoration: "none",
                    },
                  }}
                >
                  Terms of Service
                </Link>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};

export default Footer;
