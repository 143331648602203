import React from "react";
import {
  Box,
  Grid,
  TextField,
  Button,
  Typography,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";

const CareerForm = () => {
  return (
    <div>
      <Box
        sx={{
          width: {xs:'85%', md:'85%'},
          display: {xs:'block',md:"flex"},
          margin: "auto",
          backgroundColor: "#fff",
          padding: "100px 25px",
        }}
      >
        <Box
          sx={{
            width: {xs:'90%', md:'50%'},
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom:{xs:'20px', md:'0px'}
          }}
        >
          <Typography
            variant="h1"
            color="#00000"
            fontWeight="bold"
            sx={{ fontSize: "56px" }}
          >
            Open position
          </Typography>
        </Box>

        <Box
          sx={{
            width: {xs:'90%', md:'50%'},
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <form>
            <Grid container spacing={2}>
              {/* First Row */}
              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{
                    sx: {
                      color: "black",
                    },
                  }}
                  InputProps={{
                    sx: {
                      color: "black",
                      "& .MuiInputBase-input": {
                        color: "black",
                      },
                      "&::placeholder": {
                        color: "black",
                        opacity: 1,
                      },
                    },
                  }}
                  variant="outlined"
                  fullWidth
                  sx={{
                    backgroundColor: "transparent",
                    borderRadius: "4px",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "black",
                      },
                      "&:hover fieldset": {
                        borderColor: "black",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "black",
                      },
                    },
                  }}
                  placeholder="First Name"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{
                    sx: {
                      color: "black",
                    },
                  }}
                  InputProps={{
                    sx: {
                      color: "black",
                      "& .MuiInputBase-input": {
                        color: "black",
                      },
                      "&::placeholder": {
                        color: "black",
                        opacity: 1,
                      },
                    },
                  }}
                  variant="outlined"
                  fullWidth
                  sx={{
                    backgroundColor: "transparent",
                    borderRadius: "4px",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "black",
                      },
                      "&:hover fieldset": {
                        borderColor: "black",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "black",
                      },
                    },
                  }}
                  placeholder="Last Name"
                />
              </Grid>

              {/* Second Row */}
              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{
                    sx: {
                      color: "black",
                    },
                  }}
                  InputProps={{
                    sx: {
                      color: "black",
                      "& .MuiInputBase-input": {
                        color: "black",
                      },
                      "&::placeholder": {
                        color: "black",
                        opacity: 1,
                      },
                    },
                  }}
                  variant="outlined"
                  fullWidth
                  sx={{
                    backgroundColor: "transparent",
                    borderRadius: "4px",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "black",
                      },
                      "&:hover fieldset": {
                        borderColor: "black",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "black",
                      },
                    },
                  }}
                  placeholder="Email"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{
                    sx: {
                      color: "black",
                    },
                  }}
                  InputProps={{
                    sx: {
                      color: "black",
                      "& .MuiInputBase-input": {
                        color: "black",
                      },
                      "&::placeholder": {
                        color: "black",
                        opacity: 1,
                      },
                    },
                  }}
                  variant="outlined"
                  fullWidth
                  sx={{
                    backgroundColor: "transparent",
                    borderRadius: "4px",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "black",
                      },
                      "&:hover fieldset": {
                        borderColor: "black",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "black",
                      },
                    },
                  }}
                  placeholder="Contact No."
                />
              </Grid>

              {/* Third Row */}
              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  sx={{
                    backgroundColor: "transparent",
                    borderRadius: "4px",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "black",
                      },
                      "&:hover fieldset": {
                        borderColor: "black",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "black",
                      },
                    },
                  }}
                >
                  <Select
                    displayEmpty
                    id="position"
                    variant="outlined"
                    sx={{
                      color: "black",
                      backgroundColor: "transparent",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "black",
                        },
                        "&:hover fieldset": {
                          borderColor: "black",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black",
                        },
                      },
                      "& .MuiInputBase-input": {
                        color: "black",
                      },
                    }}
                    renderValue={(selected) => {
                      if (!selected) {
                        return (
                          <span style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                            Select Position
                          </span>
                        );
                      }
                      return selected;
                    }}
                  >
                    <MenuItem value="">
                      <em>Select Position</em>
                    </MenuItem>
                    <MenuItem value={1}>First</MenuItem>
                    <MenuItem value={2}>Second</MenuItem>
                    <MenuItem value={3}>Third</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* Fourth Row */}
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    width: "150px",
                    color: "black",
                    padding: "10px",
                    borderRadius: "50px",
                    backgroundColor: "#e4ba01",
                    fontWeight: "bold",
                    "&:hover": {
                      color: "black",
                      backgroundColor: "#e4ba01",
                    },
                  }}
                  type="submit"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
    </div>
  );
};

export default CareerForm;
