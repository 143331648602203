import React from "react";
import { Box, Typography } from "@mui/material";

const partnerLogos = [
  "https://vikasrabi.com/Images/logo_lg.png",
  "https://upload.wikimedia.org/wikipedia/en/5/55/Logo_of_Indian_Council_of_Agricultural_Research.png",
  "https://icar-nrri.in/wp-content/uploads/2019/08/nrri.png",
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPh7ubeYWdm6yVM1YYjhFaY8tS9rLSh4Vn0wNhr3Aeuae7OIWH523Zk9PfYsnXPNoD1nE&usqp=CAU"
];

const Partners = () => {
  return (
    <div>
      <Box
        sx={{
          width: "85%",
          display: "flex",
          flexDirection: "column",
          margin: "auto",
        }}
      >
        <Typography
          variant="h1"
          color="#fff"
          fontWeight="bold"
          sx={{ fontSize: "56px", textAlign: "center", marginBottom: '50px' }}
        >
          Our Partners
        </Typography>

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: { xs: 'repeat(2, 1fr)', md: 'repeat(4, 1fr)' },
            gap: "50px",
            margin: "50px 0px",
          }}
        >
          {partnerLogos.map((logo, index) => (
            <Box
              key={index}
              sx={{
                height: "200px",
                display: "flex",
                overflow: "hidden",
              }}
            >
              <img
                src={logo}
                alt={`Partner logo ${index + 1}`}
                width="100%"
                style={{ objectFit: "contain" }}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </div>
  );
};

export default Partners;