import React,{useEffect} from "react";
import { Box, Typography } from "@mui/material";
import WasterDrop from "../../assets/p-water-drop.png";
import Mglass from "../../assets/m-glass.png";
import Cards from "./cards";
import gifAqua from "../../assets/p-aqua-project.gif";
import AOS from "aos";
import "aos/dist/aos.css";

const Products = () => {

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);


  return (
    <div
      style={{
        backgroundColor: "#041e04",
        paddingTop: "200px",
        paddingBottom: "150px",
      }}
    >
      <Box>
        <Box
          sx={{
            position: "relative",
            height: "55vh",
            width: "70%",
            margin: "auto",
            border: "20px solid",
            backgroundColor: "#0d1e03",
            borderImage: "linear-gradient(90deg, #778bd2, #1fc0dc, #3063e5) 1",
            borderBottom: "none",
            borderRadius: "25px",
            boxShadow: `0 0 97px #1fc0dc, 0 0 68px #3063e5, 0 0 0px #77aed2c9 `,
          }}
        >
          <Typography
            variant="h1"
            color="#d6f4f2"
            fontWeight="bold"
            sx={{
              fontSize: "330px",
              position: "absolute",
              bottom: "-98px",
              left: "-89px",
            }}
          >
            Products
          </Typography>
          <Box
          data-aos="fade-down"
            sx={{
              height: "40vh",
              position: "fixed",
              right: "267px",
            }}
          >
            <img
              src={WasterDrop}
              alt="water drop"
              width="100%"
              height="100%"
              style={{ objectFit: "contain" }}
            />
          </Box>
        </Box>

        <Box
          sx={{
            width: "80%",
            margin: "auto",
            marginTop: "100px",
            display: "flex",
          }}
        >
          <Box
            sx={{
              width: "40%",
              display: "flex",
              flexDirection: "column",
              gap: "15px",
              justifyContent: "center",
            }}
          >
            <Typography variant="h1" color="#fff" fontWeight="bold">
              Water Testing Kit
            </Typography>
            <Typography variant="h4" color="#fff">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed eum
              mollitia deserunt, omnis ratione possimus odit optio iusto unde
              nesciunt quo rem, facere iure nemo.
            </Typography>
          </Box>
          <Box sx={{ width: "60%", height: "81vh" }}>
            <img
              src={Mglass}
              alt="water drop"
              width="100%"
              height="100%"
              style={{ objectFit: "cover" }}
            />
          </Box>
        </Box>
      </Box>

      <Box sx={{ width: "80%", margin: "auto", marginTop: "100px" }}>
        <Cards />
      </Box>

      <Box
        sx={{
          width: "80%",
          margin: "auto",
          marginTop: "100px",
          height: "70vh",
        }}
      >
        <img src={gifAqua} alt="gif" width="100%" height="100%" />
      </Box>
    </div>
  );
};

export default Products;
