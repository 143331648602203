import React from "react";
import { Box, useTheme, Grid, Typography } from "@mui/material";
import { tokens } from "../../theme";
import LearnMoreComponent from "./learnMore";
import Partners from "../about/partners";
import { margin, positions } from "@mui/system";
import OurJourney from "./ourJourney";
import ProblemsCarousel from "./problemsCarousel";

const cardItems = [
  {
    title: "Aquaponics",
    imageUrl:
      "https://images.unsplash.com/photo-1724582586413-6b69e1c94a17?q=80&w=1856&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    title: "Aquaculture",
    imageUrl:
      "https://images.unsplash.com/photo-1724582586413-6b69e1c94a17?q=80&w=1856&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    title: "Hydroponics",
    imageUrl:
      "https://images.unsplash.com/photo-1724582586413-6b69e1c94a17?q=80&w=1856&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
];

const Home = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <div
      style={{
        backgroundColor: "#041e04",
        paddingTop: "200px",
        paddingBottom: "150px",
      }}
    >
      <Box sx={{ marginTop: "100px", width: "90%", margin: "auto" }}>
        <Grid container spacing={5} sx={{ justifyContent: "center" }}>
          {cardItems.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <Box
                sx={{
                  backgroundImage: `url(${item.imageUrl})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  height: "40vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  position: "relative",
                  color: "#fff",
                  borderRadius: "15px",
                  transition: "all 0.3s ease",
                  cursor: "pointer",
                  "&:hover": {
                    transform: "scale(1.1)",
                  },
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    color: "white",
                    marginTop:'5vh',
                    fontSize:'56px',
                    fontWeight:'bold'
                  }}
                >
                  {item.title}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* <Box sx={{ marginTop: "100px" }}>
        <ProblemsCarousel />
      </Box> */}

      <Box sx={{ marginTop: "100px", width:'60%', margin:'auto' }}>
        <OurJourney />
      </Box>

      <Box sx={{ marginTop: "100px" }}>
        <LearnMoreComponent />
      </Box>

      <Box sx={{ marginTop: "100px" }}>
        <Partners />
      </Box>
    </div>
  );
};

export default Home;
