import React from "react";
import { Grid, Card, CardMedia, CardContent, Typography } from "@mui/material";
import IconOne from "../../../assets/icons/icon-1.png";
import IconTwo from "../../../assets/icons/icon-2.png";
import IconThree from "../../../assets/icons/icon-3.png";
import IconFour from "../../../assets/icons/icon-4.png";
import IconFive from "../../../assets/icons/icon-5.png";
import TypingText from "../../../components/typingText";

const items = [
  {
    title:
      "Designed to measure the water parameters on daily basis without using any kind of digital sensors. Thus making it affordable.",
    imageUrl: IconOne,
  },
  {
    title:
      "The technical automation & IOT enabled self-drive & control system than can a user operate from his comfort by a smart phone from anywhere.",
    imageUrl: IconTwo,
  },
  {
    title:
      "Machine is operate through solar electrical energy that completely reduces the cost of running with no reoccurrence cost / maintenance.",
    imageUrl: IconThree,
  },
  {
    title:
      "Machine can be operated for multiple ponds at a time machinery can monitor multiple ponds at a time by using micro controller, camera, pupms and sensors.",
    imageUrl: IconFour,
  },
  {
    title:
      "Integrated cloud storage system. After testing all the data parameters will be stored in cloud which can be monitored through real time monitoring app. Also, all the data's stored in cloud can be used for future prediction.",
    imageUrl: IconFive,
  },
];

const Cards = () => {

  return (
    <div>
      <Grid container spacing={2} sx={{ justifyContent: "center" }}>
        {items.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
            <Card
              sx={{
                backgroundColor: "#C3E6E8",
                borderRadius: "25px",
                height: "40vh",
              }}
            >
              <CardMedia
                data-aos="flip-left"
                component="img"
                height="120"
                image={item.imageUrl}
                sx={{ objectFit: "contain", padding: "10px" }}
              />
              <CardContent>
                <Typography
                  variant="h5"
                  component="div"
                >
                  {item.title}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Cards;
