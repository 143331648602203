import React from "react";
import { Box, Typography, Button } from "@mui/material";
import CareerForm from "./CareerForm";
import JoinUsImage from "../../../assets/career-1.png";
import JoinedMembers from "./JoinedMembers";

const CareerComponent = () => {
  return (
    <div >
      <Box sx={{ width: "85%", display: {xs:'block', md:"flex"}, margin: "auto" }}>
        <Box
          sx={{
            width: {xs:'100%',md:"40%"},
            display: 'flex',
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "center",
            marginBottom:{xs:'20px', md:'0px'}
          }}
        >
          <Typography
            variant="h1"
            color="#fff"
            fontWeight="bold"
            sx={{ fontSize: "56px" }}
          >
            Join Our Team
          </Typography>
          <Typography variant="h3" color="#fff" sx={{ marginTop: "15px" }}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum
            animi nihil neque reprehenderit labore aliquam, cupiditate
            laboriosam soluta ducimus adipisci!
          </Typography>

          <Button
            variant="contained"
            sx={{
              width: "250px",
              color: "black",
              padding: "10px",
              borderRadius: "50px",
              backgroundColor: "#e4ba01",
              fontWeight: "bold",
              marginTop: "15px",
              "&:hover": {
                color: "white",
                backgroundColor: "#e4ba01",
              },
            }}
            type="submit"
          >
            Open Positions
          </Button>
        </Box>
        <Box sx={{ width: {xs:'100%',md:"60%"}}}>
          <img src={JoinUsImage} alt="image" width="100%" />
        </Box>
      </Box>

      {/* Joined Members */}
      <Box sx={{marginTop:'50px'}}>
        <JoinedMembers />
      </Box>

      {/* Career Form */}
      <Box sx={{marginTop:'50px'}}>
        <CareerForm />
      </Box>
    </div>
  );
};

export default CareerComponent;
