import React from "react";
import { Box, Grid, TextField, Button, Typography } from "@mui/material";
import CareerComponent from "./career";

const Contact = () => {
  return (
    <div style={{ backgroundColor: "#041e04", paddingTop:'200px', paddingBottom:'150px' }}>
      <Box
        sx={{ width: "85%", display: {xs:'block',md:"flex"}, margin: "auto", marginBottom:'25px' }}
      >
        
        <Box
          sx={{
            width: {xs:'100%',md:"50%"},
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom:{xs:'20px', md:'0px'}
          }}
        >
          <form>
            <Grid container spacing={2}>
              {/* First Row */}
              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{
                    sx: {
                      color: "white",
                    },
                  }}
                  InputProps={{
                    sx: {
                      color: "white",
                      "& .MuiInputBase-input": {
                        color: "white",
                      },
                      "&::placeholder": {
                        color: "white",
                        opacity: 1,
                      },
                    },
                  }}
                  variant="outlined"
                  fullWidth
                  sx={{
                    backgroundColor: "transparent",
                    borderRadius: "4px",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "white",
                      },
                      "&:hover fieldset": {
                        borderColor: "white",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "white",
                      },
                    },
                  }}
                  placeholder="First Name"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{
                    sx: {
                      color: "white",
                    },
                  }}
                  InputProps={{
                    sx: {
                      color: "white",
                      "& .MuiInputBase-input": {
                        color: "white",
                      },
                      "&::placeholder": {
                        color: "white",
                        opacity: 1,
                      },
                    },
                  }}
                  variant="outlined"
                  fullWidth
                  sx={{
                    backgroundColor: "transparent",
                    borderRadius: "4px",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "white",
                      },
                      "&:hover fieldset": {
                        borderColor: "white",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "white",
                      },
                    },
                  }}
                  placeholder="Last Name"
                />
              </Grid>

              {/* Second Row */}
              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{
                    sx: {
                      color: "white",
                    },
                  }}
                  InputProps={{
                    sx: {
                      color: "white",
                      "& .MuiInputBase-input": {
                        color: "white",
                      },
                      "&::placeholder": {
                        color: "white",
                        opacity: 1,
                      },
                    },
                  }}
                  variant="outlined"
                  fullWidth
                  sx={{
                    backgroundColor: "transparent",
                    borderRadius: "4px",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "white",
                      },
                      "&:hover fieldset": {
                        borderColor: "white",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "white",
                      },
                    },
                  }}
                  placeholder="Email"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{
                    sx: {
                      color: "white",
                    },
                  }}
                  InputProps={{
                    sx: {
                      color: "white",
                      "& .MuiInputBase-input": {
                        color: "white",
                      },
                      "&::placeholder": {
                        color: "white",
                        opacity: 1,
                      },
                    },
                  }}
                  variant="outlined"
                  fullWidth
                  sx={{
                    backgroundColor: "transparent",
                    borderRadius: "4px",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "white",
                      },
                      "&:hover fieldset": {
                        borderColor: "white",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "white",
                      },
                    },
                  }}
                  placeholder="Contact No."
                />
              </Grid>

              {/* Third Row */}
              <Grid item xs={12}>
                <TextField
                  InputLabelProps={{
                    sx: {
                      color: "white",
                    },
                  }}
                  InputProps={{
                    sx: {
                      color: "white",
                      "& .MuiInputBase-input": {
                        color: "white",
                      },
                      "&::placeholder": {
                        color: "white",
                        opacity: 1,
                      },
                    },
                  }}
                  multiline
                  maxRows={5}
                  minRows={5}
                  variant="outlined"
                  fullWidth
                  sx={{
                    backgroundColor: "transparent",
                    borderRadius: "4px",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "white",
                      },
                      "&:hover fieldset": {
                        borderColor: "white",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "white",
                      },
                    },
                  }}
                  placeholder="How Can We Help You"
                />
              </Grid>

              {/* Fourth Row */}
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    width: "100%",
                    color: "black",
                    padding: "10px",
                    borderRadius: "50px",
                    backgroundColor: "#e4ba01",
                    fontWeight: "bold",
                    "&:hover": {
                      color: "white",
                      backgroundColor: "#e4ba01",
                    },
                  }}
                  type="submit"
                >
                  Get In Touch
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
        <Box
          sx={{
            width: {xs:'100%',md:"50%"},
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="h1" color="#fff" fontWeight="bold" sx={{ fontSize: "56px" }}>
            Contact Us
          </Typography>
        </Box>
      </Box>

      {/* Career Page Component  */}
      <Box>
        <CareerComponent/>
      </Box>

    </div>
  );
};

export default Contact;
