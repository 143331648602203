import React,{useEffect} from "react";
import { Box, Typography } from "@mui/material";
import HeaderImage from "../../assets/about_header.png";
import Microscope from "../../assets/microscope.png";
import MissionAndVision from "./missionAndVision";
import Partners from "./partners";
import Recognization from "./recognization";
import AOS from "aos";
import "aos/dist/aos.css";

const About = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div
      style={{
        backgroundColor: "#041e04",
        paddingTop: "200px",
        paddingBottom: "150px",
      }}
    >
      <Box
        sx={{
          width: "85%",
          display: {xs:"block",md:"flex"},
          margin: "auto",
          marginBottom: "25px",
        }}
      >
        <Box
        data-aos="fade-right"
          sx={{
            width: {xs:"100%",md:"40%"},
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginBottom:{xs:'20px', md:'0px'}
          }}
        >
          <Typography
            variant="h1"
            color="#fff"
            fontWeight="bold"
            sx={{ fontSize: "56px" }}
          >
            Know More About Us
          </Typography>

          <Typography variant="h3" color="#fff">
            A place where innovation meets agriculture. In a mission to take
            farming profitable, with our products and services.
          </Typography>
        </Box>
        <Box
          sx={{
            width: {xs:"100%",md:"60%"},
          }}
        >
          <img src={HeaderImage} alt="image" width="100%" data-aos="fade-left"/>
        </Box>
      </Box>

      <Box
        sx={{
          width: {xs:"auto",md:"70%"},
          display: "flex",
          margin: "auto",
          marginBottom: "100px",
          backgroundColor:'#b3cdb3',
          borderRadius:'20px',
          position:'relative',
          alignItems:'center',
          justifyContent:'center',
          height:{xs:'100%',md:'80vh',lg:'70vh'},
          padding:{xs:'20px',md:'10px 100px 100px 100px'},
          marginLeft:{xs:'10px', md:'auto'},
          marginRight:{xs:'10px', md:'auto'}
        }}
      >
        <Box sx={{textAlign:'center'}}>
          <Typography
            variant="h1"
            color="#00000"
            fontWeight="bold"
            sx={{ fontSize: "56px", marginBottom:'35px' }}
          >
            Our Story
          </Typography>

          <Typography variant="h3" color="#00000" sx={{ marginBottom:'15px' }}>
            The idea for a one stop solution of all agricultural needs started
            at a farm when we noticed that farmers are switching from farming to
            other jobs as its getting difficult for the farmers to cope up with
            this fast pace world where the demand is increasing day by day but
            the production is not high, gone are those days where farmers are
            dependent on faith, due to which there are many losses then gains,
            there is a need for change and incorporating different and
            innovative methods, its not replacement for traditional method but
            an enhancement or improvement of traditional methods. while
            designing our services and products our main motive is to make
            farming efficient, effective, profitable and most importantly
            implement sustainable and making there business profitable.
          </Typography>
          <Typography variant="h3" color="#00000">
            While designing our services and products our main motive is to make
            farming efficient, effective, profitable and most importantly
            implement sustainable and making there business profitable.
          </Typography>
        </Box>
        <Box sx={{backgroundColor:"white", position:'absolute', bottom:'0px', left:'0px', height:'10vh' }}/>

        <Box data-aos="fade-up" sx={{position:'absolute', bottom:'-100px', right:'35px', display:{xs:'none',sm:'none',md:'none', lg:'block'}}}>
          <img src={Microscope} alt="image" width="100%" height="300px" style={{objectFit:'cover'}} />
        </Box>
      </Box>

      <Box sx={{marginBottom: "100px",}}>
        <MissionAndVision/>
      </Box>

      <Box sx={{marginBottom: "100px",}}>
        <Partners/>
      </Box>

      <Box sx={{marginBottom: "50px",}}>
        <Recognization/>
      </Box>
    </div>
  );
};

export default About;
