import FavoriteIcon from "@mui/icons-material/Favorite";

import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
} from "@mui/material";
import "./style.blogList.css";
import BlogCarousel from "./BlogCarousel";

const blogs = [
  {
    id: 1,
    title:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard ",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard ",
    image:
      "https://images.unsplash.com/photo-1724582586413-6b69e1c94a17?q=80&w=1856&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: 2,
    title:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard ",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard ",
    image:
      "https://plus.unsplash.com/premium_photo-1692640261266-e587473ddee9?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: 3,
    title:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard ",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard ",
    image:
      "https://images.unsplash.com/photo-1724592752478-c4b75032d7e0?q=80&w=2039&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: 4,
    title:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard ",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard ",
    image:
      "https://images.unsplash.com/photo-1720048171731-15b3d9d5473f?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: 5,
    title:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard ",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard ",
    image:
      "https://images.unsplash.com/photo-1720048171731-15b3d9d5473f?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
];

const BlogList = () => {
  const [mainBlog, setMainBlog] = useState(blogs[0]);
  const [sideBlogs, setSideBlogs] = useState(blogs.slice(1));

  const handleBlogClick = (blog) => {
    setSideBlogs([mainBlog, ...sideBlogs.filter((b) => b.id !== blog.id)]);
    setMainBlog(blog);
  };

  return (
    <>
      <Box className="blog-container">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={8}>
            <Box
              className="main-card"
              sx={{
                backgroundImage: `url(${mainBlog.image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: "80vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                position: "relative",
                color: "#fff",
                borderRadius: "15px",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  borderRadius: "12px",
                  position: "absolute",
                  bottom: "0px",
                  left: "0px",
                  width: "98%",
                  padding: "10px 8px",
                }}
              >
                <Box>
                  <Typography variant="h3" sx={{ color: "white" }}>
                    {mainBlog.title}
                  </Typography>
                  <Typography variant="h5" sx={{ color: "white" }}>
                    {mainBlog.desc}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "10px 20px",
                  }}
                >
                  <Box sx={{ display: "flex", gap: "10px" }}>
                    <Typography
                      variant="h6"
                      sx={{
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        padding: "0px 15px",
                        borderRadius: "12px",
                        color: "white",
                        border: "2px solid white",
                        display:'flex',
                        alignItems:'center'
                      }}
                    >
                      Aquaponics
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        padding: "0px 15px",
                        borderRadius: "12px",
                        color: "white",
                        border: "2px solid white",
                        display:'flex',
                        alignItems:'center'
                      }}
                    >
                      Sustainability
                    </Typography>
                  </Box>
                  <Typography variant="h4">
                    <FavoriteIcon
                      sx={{ display: "flex", fontSize: "36px", color: "red" }}
                    />
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <div className="side-blogs">
              {sideBlogs.map((blog) => (
                <Box
                  key={blog.id}
                  className="side-card"
                  onClick={() => handleBlogClick(blog)}
                >
                  <Box className="main-card">
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <Typography variant="h5" sx={{ color: "white" }}>
                        {mainBlog.title}
                      </Typography>
                      <Box sx={{ display: "flex", gap: "10px" }}>
                        <Typography
                          variant="h6"
                          sx={{
                            padding: "0px 15px",
                            borderRadius: "12px",
                            color: "white",
                            border: "2px solid white",
                          }}
                        >
                          Aquaponics
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{
                            padding: "0px 15px",
                            borderRadius: "12px",
                            color: "white",
                            border: "2px solid white",
                          }}
                        >
                          Sustainability
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <img
                        src={mainBlog.image}
                        alt={mainBlog.title}
                        height="110px"
                        width="150px"
                        style={{ borderRadius: "10px" }}
                      />
                    </Box>
                  </Box>
                </Box>
              ))}
            </div>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{marginTop:'100px'}}>
      <BlogCarousel />
      </Box>
    </>
  );
};

export default BlogList;
