import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { createTheme, ThemeProvider } from "@mui/material";

const root = ReactDOM.createRoot(document.getElementById("root"));

const styleTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: ["newsbt"].join(","),
    fontSize: 12,
    h1: {
      fontFamily: ["newsbt"].join(","),
      fontSize: 34,
    },
    h2: {
      fontFamily: ["newsbt"].join(","),
      fontSize: 32,
    },
    h3: {
      fontFamily: ["newsbt"].join(","),
      fontSize: 22,
    },
    h4: {
      fontFamily: ["newsbt"].join(","),
      fontSize: 18,
    },
    h5: {
      fontFamily: ["newsbt"].join(","),
      fontSize: 16,
    },
    h6: {
      fontFamily: ["newsbt"].join(","),
      fontSize: 14,
    },
  },
});

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={styleTheme}>
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
