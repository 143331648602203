import { AppBar, Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import React, { useState } from "react";

const Header = () => {
  const [selected, setSelected] = useState("home");

  const handleClick = (option) => {
    setSelected(option);
  };

  return (
    <AppBar
      position="sticky"
      sx={{
        display: "flex",
        backgroundColor: "#818181",
        alignItems: "center",
        justifyContent: "center",
        width: "fit-content",
        padding: "8px 30px",
        borderRadius: "50px",
        margin: "auto",
        position: "fixed",
        top: "7vh",
        left: "0%",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Link
          to="/"
          style={{
            textDecoration: "none",
            cursor: "pointer",
            marginRight: "25px",
          }}
        >
          <img src="" alt="logo" />
        </Link>

        <Box sx={{ display: "flex", width: "100%", gap: "45px" }}>
          <Typography
            variant="h3"
            onClick={() => handleClick("home")}
          >
            <Link
              to="/"
              style={{
                textDecoration: "none",
                color: selected === "home" ? "#073807" : "#fff",
                "&:hover": {
                  textDecoration: "none",
                },
              }}
            >
              Home
            </Link>
          </Typography>
          <Typography variant="h3"
          onClick={() => handleClick("about")}>
            <Link
              to="/about"
              style={{
                color: selected === "about" ? "#073807" : "#fff",
                textDecoration: "none",
                "&:hover": {
                  textDecoration: "none",
                },
              }}
            >
              About
            </Link>
          </Typography>
          <Typography variant="h3"
          onClick={() => handleClick("product")}>
            <Link
              to="/products"
              style={{
                color: selected === "product" ? "#073807" : "#fff",
                textDecoration: "none",
                "&:hover": {
                  textDecoration: "none",
                },
              }}
            >
              Product
            </Link>
          </Typography>
          <Typography variant="h3"
          onClick={() => handleClick("blog")}>
            <Link
              to="/blog"
              style={{
                color: selected === "blog" ? "#073807" : "#fff",
                textDecoration: "none",
                "&:hover": {
                  textDecoration: "none",
                },
              }}
            >
              Blog
            </Link>
          </Typography>
          <Typography variant="h3"
          onClick={() => handleClick("contact")}>
            <Link
              to="/contact"
              style={{
                color: selected === "contact" ? "#073807" : "#fff",
                textDecoration: "none",
                "&:hover": {
                  textDecoration: "none",
                },
              }}
            >
              Contact Us
            </Link>
          </Typography>
        </Box>
      </Box>
    </AppBar>
  );
};

export default Header;
