import "./styled.module.css";
import pinIcon from "../../../assets/icons/pin.png";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { Box, Typography } from "@mui/material";

let timelineElements = [
  {
    id: 1,
    title: "",
    description:
      "It started with a problem identified of expensive water testing kit which is difficult for small scale farmers to afford to in order to tackle that.",
    icon:"pin",
  },
  {
    id: 2,
    title: "",
    description:
      "This was just a mere idea so, we had to confirm what's actually happening in the farms and what their needs were.",
      icon:"pinned",
  },
  {
    id: 3,
    title: "",
    description:
      "Did proper survey, on field interactions with farmers and other stakeholders.",
      icon:"pin",
  },
  {
    id: 4,
    title: "",
    description:
      "With all the surveys and research not only one but many such problems were identified.",
      icon:"pinned",
  },
  {
    id: 5,
    title: "",
    description:
      "That’s how creative pathbreakers started, focused to find innovative and creative solutions and explore the unexplored paths.",
      icon:"pin",
  },
  {
    id: 6,
    title: "",
    description:
      "Our area of specialization are aquaponics, aquaculture and hydroponics. We make products and innovative solutions for farmers",
      icon:"pinned",
  },
  {
    id: 7,
    title: "",
    description:
      "Product development and designing. Water testing kit. An automated water testing kit, this product is designed to measure the water parameters on daily basis without using any kind of digital sensors. ",
      icon:"pin",
  },
];

const OurJourney = () => {
  let pinIconStyles = { background: "#06D6A0" };
  let pinedIconStyles = { background: "#d0d92f" };

  return (
    <div>
      <Typography
        variant="h1"
        sx={{
          color: "white",
          fontSize: "56px",
          fontWeight: "bold",
          textAlign: "center",
          marginBottom:'25px'
        }}
      >
        Our Journey
      </Typography>
      <VerticalTimeline>
        {timelineElements.map((element) => {
          return (
            <VerticalTimelineElement
              key={element.id}
              //   date={element.date}
              dateClassName="date"
              iconStyle={element.icon === "pin" ? pinIconStyles : pinedIconStyles}
              icon={
                <img
                  src={pinIcon}
                  alt={"Pin Icon"}
                  style={{ width: "100%", height: "100%", padding: "10px" }}
                />
              }
              contentStyle={{ background: "#1a311a" }}
            >

              <Typography
                variant="h4"
                sx={{
                  color: "white",
                }}
              >
                {element.description}
              </Typography>
            </VerticalTimelineElement>
          );
        })}
      </VerticalTimeline>
    </div>
  );
};

export default OurJourney;
