import React, { useState } from "react";
import { Box, Grid, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";

const LearnMore = [
  {
    id: 1,
    title: "Lorem Ipsum is simply",
    readMore: "https://www.google.com",
    image:
      "https://images.unsplash.com/photo-1724582586413-6b69e1c94a17?q=80&w=1856&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: 2,
    title: "Lorem Ipsum is simply",
    readMore: "https://www.google.com",
    image:
      "https://plus.unsplash.com/premium_photo-1692640261266-e587473ddee9?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: 3,
    title: "Lorem Ipsum is simply",
    readMore: "https://www.google.com",
    image:
      "https://images.unsplash.com/photo-1724592752478-c4b75032d7e0?q=80&w=2039&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: 4,
    title: "Lorem Ipsum is simply",
    readMore: "https://www.google.com",
    image:
      "https://images.unsplash.com/photo-1720048171731-15b3d9d5473f?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: 5,
    title: "Lorem Ipsum is simply",
    readMore: "https://www.google.com",
    image:
      "https://images.unsplash.com/photo-1720048171731-15b3d9d5473f?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: 6,
    title: "Lorem Ipsum is simply",
    readMore: "https://www.google.com",
    image:
      "https://images.unsplash.com/photo-1720048171731-15b3d9d5473f?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: 7,
    title: "Lorem Ipsum is simply",
    readMore: "https://www.google.com",
    image:
      "https://images.unsplash.com/photo-1720048171731-15b3d9d5473f?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: 8,
    title: "Lorem Ipsum is simply",
    readMore: "https://www.google.com",
    image:
      "https://images.unsplash.com/photo-1720048171731-15b3d9d5473f?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: 9,
    title: "Lorem Ipsum is simply",
    readMore: "https://www.google.com",
    image:
      "https://images.unsplash.com/photo-1720048171731-15b3d9d5473f?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
];

const LearnMoreComponent = () => {
  return (
    <div>
      <Box
        sx={{
          width: "90%",
          margin: "auto",
          display: {xs:'block', sm:'block', md:'flex', lg:'flex'},
          alignItems: "center",
          
        }}
      >
        <Box
          sx={{
            width: {xs:'100%', sm:'100%', md:'30%', lg:'30%'},
            gap: "10px",
            marginBottom: {xs:'20px', sm:'20px', md:'0px', lg:'0px'},
          }}
        >
          <Typography
            variant="h1"
            sx={{ color: "white", fontWeight: "bold", fontSize: "56px" }}
          >
            Learn More
          </Typography>
          <Typography variant="h3" sx={{ color: "white" }}>
            We are really fond of sharing our knowledge and experiences with the
            world, Get your hands on the pool of knowledge.
          </Typography>

          <Button
            variant="contained"
            sx={{
              width: "250px",
              color: "black",
              padding: "10px",
              borderRadius: "50px",
              backgroundColor: "#e4ba01",
              fontWeight: "bold",
              marginTop: "15px",
              "&:hover": {
                color: "white",
                backgroundColor: "#e4ba01",
              },
            }}
            type="submit"
          >
            Read More
          </Button>
        </Box>

        <Box
          sx={{
            width: {xs:'100%', sm:'100%', md:'70%', lg:'70%'},
          }}
        >
          <Grid container spacing={2} sx={{ justifyContent: "center" }}>
            {LearnMore.map((item, index) => {
              let gridProps = {};
              if (index < 3 || index >= 6) {
                gridProps = { xs: 12, sm: 6, md: 3, lg: 3 };
              }
              if (index >= 3 && index < 6) {
                gridProps = { xs: 12, sm: 6, md: 4, lg: 4 };
              }
              return (
                <Grid item key={index} {...gridProps}>
                  <Box
                    sx={{
                      backgroundImage: `url(${item.image})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      height: "20vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      position: "relative",
                      color: "#fff",
                      borderRadius: "15px",
                      transition: "all 0.3s ease",
                      cursor: "pointer",
                      "&:hover": {
                        transform: "scale(1.1)",
                      },
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        padding: "0px 15px",
                        borderRadius: "12px",
                        color: "white",
                        position: "absolute",
                        top: "0px",
                        left: "0px",
                      }}
                    >
                      {item.title}
                    </Typography>

                    <Link to={item.readMore} style={{ textDecoration: "none" }}>
                      <Typography
                        variant="h6"
                        sx={{
                          padding: "0px 15px",
                          color: "white",
                          position: "absolute",
                          bottom: "20px",
                          right: "0px",
                          backgroundColor: "#5c5f6382",
                          borderTopLeftRadius: "12px",
                          borderBottomLeftRadius: "12px",
                        }}
                      >
                        Read more
                      </Typography>
                    </Link>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </div>
  );
};

export default LearnMoreComponent;
