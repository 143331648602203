import React, { useState, useEffect } from "react";
import { Button, Box, Typography, Container } from "@mui/material";
import MissVis from "../../../assets/miss-vis.png";
import "./styleModule.css";
import AOS from "aos";
import "aos/dist/aos.css";

const MissionAndVision = () => {
  const [selected, setSelected] = useState("");
  const [showImage, setShowImage] = useState(true);

  const handleClick = (option) => {
    setSelected(option);
    setShowImage(false);
  };

  return (
    <div>
      <Box
        sx={{
          display: {xs:"block",md:"flex"},
          padding: "20px",
          marginTop: "100px",
          alignItems: "center",
          width: "80%",
          margin: "auto",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            width: {xs:"100%",md:"50%"},
            display: "flex",
            flexDirection: {xs:"row",md:"column"},
            gap: "10px",
            marginBottom:{xs:'30px', md:'0px'}
          }}
        >
          <Button
            variant="contained"
            sx={{
              width: {xs:"50%", md:"20vw"},
              padding: "20px 25px",
              fontSize: "42px",
              backgroundColor: selected === "mission" ? "#179e17" : "#b3cdb3",
              color: selected === "mission" ? "white" : "white",
              transition: "background-color 0.3s",
              "&:hover": {
                backgroundColor: "white",
                color: "black",
              },
            }}
            onClick={() => handleClick("mission")}
          >
            Mission
          </Button>
          <Button
            variant="contained"
            sx={{
              width: {xs:"50%", md:"20vw"},
              padding: "20px 25px",
              fontSize: "42px",
              backgroundColor: selected === "vision" ? "#179e17" : "#b3cdb3",
              color: selected === "vision" ? "white" : "white",
              transition: "background-color 0.3s",
              "&:hover": {
                backgroundColor: "white",
                color: "black",
              },
            }}
            onClick={() => handleClick("vision")}
          >
            Vision
          </Button>
        </Box>

        <Box
          sx={{
            width: {xs:"100%",md:"50%"},
            paddingLeft: "20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {showImage ? (
            <img
              src={MissVis}
              alt="Placeholder"
              className={showImage ? "" : "image-slide-out"}
              style={{
                maxWidth: "100%",
                borderRadius: "8px",
                overflow: 'hidden',
              }}
              data-aos="fade-right"
            />
          ) : (
            <Typography
              className={showImage ? "" : "text-slide-in"}
              variant="h3"
              sx={{ color: "white" }}
            >
              {selected === "mission"
                ? "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Necessitatibus unde ex aliquam inventore. Voluptate nulla, beatae obcaecati maxime nemo pariatur hic exercitationem voluptatum excepturi assumenda."
                : "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Necessitatibus unde ex aliquam inventore. Voluptate nulla, beatae obcaecati maxime nemo pariatur hic exercitationem voluptatum excepturi assumenda.Necessitatibus unde ex aliquam inventore. Voluptate nulla, beatae obcaecati maxime nemo pariatur hic exercitationem voluptatum excepturi assumenda."}
            </Typography>
          )}
        </Box>
      </Box>
    </div>
  );
};

export default MissionAndVision;
